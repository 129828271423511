<template>
  <base-section id="about-us">
    <base-section-heading title="About Us">
      AWM Research and Consulting, a London based company working on software
      development since 2013. We are eager to work with you to design and
      develop tailormade solutions that will help your business to scale.
    </base-section-heading>

    <v-container>
      <!-- <v-col class="text-center">
        <span>We are here to help, give us a call or send us an email</span>
      </v-col>
      <v-col class="text-center">
        <v-btn elevation="2" fab class="ma-5" color="primary"
          ><v-icon>mdi-mail</v-icon>
        </v-btn>
        <v-btn elevation="2" fab color="secondary"
          ><v-icon>mdi-phone</v-icon>
        </v-btn>
      </v-col> -->
      <v-col>
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <div>Get in touch</div>
            <p class="text-h6 text--primary">AWM Rasearch & Consulting Ltd</p>

            <div class="text--primary">
              124 City Road, EC1V 2NX<br />
              London (UK)
            </div>
            <div class="text--secondary mt-2">
              <v-icon small class="mr-2">mdi-cellphone</v-icon>
              <span>+44 (0) 7462 816655</span>
            </div>
            <div>
              <v-icon small class="mr-2">mdi-mail</v-icon>
              <span>info@awmconsulting.dev</span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col class="text-left">
                <v-card-subtitle
                  >We are here to help, give us a call or send us an
                  email</v-card-subtitle
                >
              </v-col>
              <!-- <v-col class="text-right" cols="4">
                <v-btn elevation="2" fab class="ma-5" color="primary"
                  ><v-icon>mdi-mail</v-icon>
                </v-btn>
                <v-btn elevation="2" fab color="secondary"
                  ><v-icon>mdi-phone</v-icon>
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-container>

    <!-- <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            illum veniam cum dolores ratione commodi beatae quas maxime,
            laboriosam excepturi solut!
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container> -->
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        title: "SMS Messaging",
        icon: "mdi-fountain-pen-tip",
        text: "AWM, through its Bulk SMS platform, offers a valid solution to Enterprises to communicate via SMS with their customers.",
      },
      {
        title: "A2P Wholesale Platform",
        icon: "mdi-cellphone",
        text: "A cloud-based white label business platform for any company that wants to be an A2P Messaging Wholesaler.",
      },
      {
        color: "primary",
        dark: true,
        title: "m-Marketing Platform",
        icon: "mdi-pencil-box-outline",
        text: "A cloud-based white label business platform for any company that wants to be a Mobile Marketing services provider.",
      },
      {
        title: "Dashboards",
        icon: "mdi-monitor-dashboard",
        text: "The 1st Business Intelligence tool, designed and developed specifically for the mobile messaging, marketing & payments market. It is offered as an add-on to all our platforms.",
      },
      {
        title: "Mobile Coupons",
        icon: "mdi-ice-pop",
        text: "AWM offers to companies the capability to run mobile coupon campaigns, in order to enrich the coupon experience of users and build the Loyalty of its customers.",
      },
      {
        title: "24/7 Support",
        icon: "mdi-help-circle-outline",
        text: "When it gets mission critical, we are there to make sure everything runs smoothly. Choose the support level that fits your needs and we will be there to support your business.",
      },
    ],
  }),
};
</script>
